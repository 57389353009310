import React from 'react';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import AllRoute from '../router';

import './App.css';


const App = () => {
  return (
    <div className="App">
      <AllRoute/>
      <Footer/>
      <Scrollbar/>
    </div>
  );
}

export default App;
