import React from 'react';

import { BrowserRouter, Route, Routes, } from "react-router-dom";
import Homepage from '../HomePage'

import couple1 from '../../images/couple/img-1.jpg'
import couple2 from '../../images/couple/img-2.jpg'

const weddingDate = new Date('2023-06-18T16:00:00.000-07:00')

const bride = {
  name: "Taos Glickman",
  image: couple1,
  blurb: ``
}

const groom = {
  name: "Buddy Sandidge",
  image: couple2,
  blurb: ``,
}


const venue = {
  name: 'Verandas Beach House',
  link: 'https://www.verandasbeachhousemb.com/',
  street: '401 Rosecrans Ave',
  city: 'Manhattan Beach, CA 90266',
  address: '401 Rosecrans Ave, Manhattan Beach, CA 90266',
  lat: 33.901580,
  long: -118.416570,
}



const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={
            <Homepage
              bride={bride}
              groom={groom}
              weddingDate={weddingDate}
              venue={venue}
            />
          }/>
          {/*
          <Route
            path='/home'
            element={
              <Homepage
                bride={bride}
                groom={groom}
                weddingDate={weddingDate}
                venue={venue}
              />
            }
          />
          <Route
            path='/home2'
            element={
              <Homepage2
                bride={bride}
                groom={groom}
                weddingDate={weddingDate}
                venue={venue}
              />
            }
          />
          <Route
            path='/home3'
            element={
              <Homepage3
                bride={bride}
                groom={groom}
                weddingDate={weddingDate}
                venue={venue}
              />
            }
          />
          <Route
            path='/home4'
            element={
              <Homepage4
                bride={bride}
                groom={groom}
                weddingDate={weddingDate}
                venue={venue}
              />
            }
          />
          <Route
            path='/Blog' element={
            <BlogPage
              bride={bride}
              groom={groom}
              weddingDate={weddingDate}
              venue={venue}
            />
          }
          />
          <Route
            path='/Blog-details'
            element={
              <BlogDetailsPage
                bride={bride}
                groom={groom}
                weddingDate={weddingDate}
                venue={venue}
              />
            }
          />
          */}
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
