import React from 'react';

import Couple from '../../components/couple';
// import Gallery from '../../components/gallery';
import Gift from '../../components/gift';
import Location from '../../components/location';
import Navbar from '../../components/Navbar'
// import People from '../../components/people';
// import Rsvp from '../../components/rsvp';
import SaveDay from '../../components/countdown'
import SimpleSlider from '../../components/hero';
// import Story from '../../components/story';
import Welcome from '../../components/welcome-area';

export default function Homepage({ bride, groom, venue, weddingDate }) {
  return (
    <div>
      <Navbar bride={bride} groom={groom}/>
      <SimpleSlider date={weddingDate}/>
      <SaveDay date={weddingDate}/>
      <Couple bride={bride} groom={groom}/>
      {/*
      <Story />
      <People />
      */}
      <Welcome />
      <Location date={weddingDate} venue={venue}/>
      {/*
      <Gallery/>
      <Rsvp/>
      */}
      <Gift/>
    </div>
  )
}
