import React, { Component } from 'react';
import './style.css'

export default function SaveDay({ date }) {
  return (
    <div className="count-down-area">
      <div className="count-down-sectionparallax">
        <div className="container">
          <div className="count-down-item count-down-item2">
            <div className="row">
              <div className="col-12 col-md-4 section-area">
                <div className="section-sub"></div>
                <h2 className="big"><span>We Are Waiting For.....</span> The Big Day</h2>
              </div>
              <div className="col-12 col-md-8 clock-area">
                <div className="count-down-clock">
                  <CountDown to={date}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

class CountDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      now: new Date(),
      date: props.to,
    }
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      this.setState({ now: new Date() });
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const diff = Math.trunc((this.state.date - this.state.now) / 1000)
    return (
      <div>
        <ul className="react-countdown">
          <Box value={days(diff)} unit='day'/>
          <Box value={hours(diff)} unit='hour'/>
          <Box value={minutes(diff)} unit='min'/>
          <Box value={seconds(diff)} unit='sec' show={true}/>
        </ul>
      </div>
    );
  }
}

function Box({ value, unit, show }) {
  if (value <= 0 && !show) {
    return
  }
  return <li>
    <p className="digit">{twoDigits(value)}</p>
    <p className="text">{unit}{value > 0 ? 's' : ''}</p>
  </li>
}


function twoDigits(value) {
  if (value.toString().length <= 1) {
    return '0' + value.toString()
  }
  return value.toString()
}

// update seconds
function seconds(diff) {
  return Math.trunc(diff) % 60
}

// update minutes
function minutes(diff) {
  return Math.trunc(diff / 60) % 60
}

// update hours
function hours(diff) {
  return Math.trunc(diff / 60 / 60) % 24
}

// update days
function days(diff) {
  return Math.trunc(diff / 60 / 60 / 24)
}

