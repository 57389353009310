import React from 'react'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'

import './style.css'


export default function Map({ name, street, city, lat, long }) {
  const position = [lat, long]
  return (
    <MapContainer center={position} zoom={16}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={position}>
        <Popup>
          <strong>{name}</strong><br/>
          {street}<br/>
          {city}<br/>
          <a href='https://goo.gl/maps/ct1sgCGTt9wjubEM9'>
            View on Google Maps
          </a>
        </Popup>
      </Marker>
    </MapContainer>
  )
}

