import React from 'react'
import SectionTitle from '../section-title'
import couple3 from '../../images/couple/2.png'
import './style.css'

export default function Couple({ bride, groom }) {
  return (
    <div id="couple" className="about-wrap">
      <div className="couple-area section-padding pb-70">

        <SectionTitle section={'Happy Couple'}/>
        <div className="container">
          <div className="couple-wrap">
            <div className="row">

              <div className="col-lg-5 col-md-12 col-sm-12 couple-single">
                <div className="couple-wrap couple-wrap-2">
                  <CoupleBlurb image={bride.image} name={bride.name} blurb={bride.blurb}/>
                </div>
              </div>

              <div className="col-lg-2 couple-single">
                <div className="couple-shape">
                  <img src={couple3} alt="shape"/>
                </div>
              </div>

              <div className="col-lg-5 col-md-12 col-sm-12 couple-single">
                <div className="couple-wrap couple-wrap-3">
                  <CoupleBlurb image={groom.image} name={groom.name} blurb={groom.blurb}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function CoupleBlurb({ name, blurb, image }) {
  return (
    <>
      <div className="couple-img couple-img-2">
        <img src={image} alt="thumb"/>
      </div>
      <div className="couple-text">
        <div className="couple-content">
          <h3>{name}</h3>
          <p>{blurb}</p>
        </div>
      </div>
    </>
  )
}
