import React from 'react';
import './style.css'

const Footer = () => {
  return (
    <div className="footer-area">
      <div className="container">
        <div className="footer-content">
          <div className="content-sub">
            <h2>Thank You</h2>
            <span>For Being With Us</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;
