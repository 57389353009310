import React from 'react'
// import { Link } from 'react-router-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import './style.css'

export default function Welcome() {
  return (
    <div className="welcome-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="welcome-content">
              <h2>Welcome to our big day</h2>
              <p>
                We invite you to join us for our summer wedding on June 18, 2023,
                in a beach house in Manhattan Beach, California.
                Ceremony starts at 4pm followed by a reception with food,
                drinks, and dancing.
              </p>

              <p>
                With love,
                Buddy and Taos
              </p>
              {/*/}
              <div className="btn"><Link to="/home">RSVP</Link></div>
              {/*/}
              <div className="btn"><AnchorLink href="#event">Location</AnchorLink></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
