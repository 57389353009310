import React from 'react'
// import { Link } from 'react-router-dom'
import Sectiontitle from '../section-title'
import Map from '../map'
import strory1 from '../../images/events/1.jpg'
import strory2 from '../../images/events/2.jpg'
// import strory3 from '../../images/events/3.jpg'
import './style.css'

export default function Location({ venue }) {
  return (
    <>
      <div id="location" className="service-area section-padding">
        <div className="container">
          <Sectiontitle section='Location'/>
          <div className="row">
            <div className="col-6">
              <h3>{venue.name}</h3>
              <p>{venue.street}</p>
              <p>{venue.city}</p>
            </div>
            <div className="col-3">
              <p>
                Our wedding will take place at the lovely {venue.name} in
                Manhattan Beach, CA. Located a short drive from LAX.
              </p>
              <p>
                <a href='https://goo.gl/maps/ct1sgCGTt9wjubEM9'>{venue.name} on Google Maps</a>
              </p>

            </div>
          </div>
          <Map {...venue} />
          <Sectiontitle section='Preview'/>
          <div className="col-12">
            <iframe title={venue.name} src="https://my.matterport.com/show/?m=DVtkBRzUYJs"
                    allowFullScreen="" width="100%"
                    height="400" frameBorder="0"></iframe>
          </div>
        </div>
      </div>

      <div id="event" className="service-area section-padding">
        <div className="container">
          <Sectiontitle section='Events'/>
          <div className="service-area-menu">
            <div className="Ceremony-wrap">
              <div className="row">
                <div className="col-lg-5">
                  <div className="ceromony-img">
                    <img src={strory1} alt=""/>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="ceromony-content">
                    <h3>Wedding Ceremony</h3>
                    <span>Sunday, June 18, 4:00-5:00 PM</span>
                    <span>{venue.addresses}</span>
                    <p>
                      The wedding ceremony will take place in the garden of a
                      charming beach cottage. The ceremony will be a short and
                      intimate affair. Drinks and hors d'Oeuvres will follow.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Ceremony-wrap">
            <div className="row">
              <div className="col-lg-7">
                <div className="ceromony-content ceromony-content2">
                  <h3>Reception Party</h3>
                  <span>Sunday, June 18, 5:00-9:00 PM</span>
                  <span>{venue.addresses}</span>
                  <p>
                    The wedding reception will take place in a cozy beach house,
                    with a laid-back coastal vibe. Guests will arrive at 5pm and be
                    greeted with drinks. The reception will feature a delicious
                    vegetarian BBQ, with a variety of options to suit every taste.
                    After dinner, there will be a time for drinks and socializing,
                    as well as some speeches and toasts to the happy couple.
                    Guests can expect to dance the night away while enjoying the
                    beautiful view of the beach. The reception will come to a
                    close at 9pm, but the memories will last a lifetime.
                  </p>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="ceromony-img">
                  <img src={strory2} alt=""/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
