import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css'

export default function SimpleSlider({ date }) {
  const settings = {
    dots: false,
    arrows: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    fade: true
  };

  return (
    <Slider {...settings}>
      <div id="home" className="item1">
        <Slide date={date}/>
      </div>
      <div className="item2">
        <Slide date={date}/>
      </div>
    </Slider>
  );
}

function Slide({ date }) {
  return (
    <div className="container">
      <div className="slide-content">
        <div className="slide-subtitle">
          <h4>WE'RE GETTING MARRIED</h4>
        </div>
        <div className="slide-title">
          <h2>Save Our Date</h2>
        </div>
        <div className="slide-text">
          <p>{
            new Intl.DateTimeFormat('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            }).format(date)
          }
          </p>
          <p>{
            new Intl.DateTimeFormat('en-US', {
              timeStyle: 'short',
            }).format(date)
          }</p>
        </div>
      </div>
    </div>
  )

}

