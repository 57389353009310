import React from "react";
import { createRoot } from 'react-dom/client';

import App from "./main-component/App/App";
import { unregister as unregisterServiceWorker } from "./serviceWorker";

import "./index.css";
import './css/font-awesome.min.css';
import './css/flaticon.css';
import './css/responsive.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const el = document.getElementById("root");
const root = createRoot(el)
root.render(<App/>)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregisterServiceWorker();
